*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

img{
    object-fit: cover;
}

a{
    color: black;
    text-decoration: none;
}

#tituloBanner{
    font-size: 1.2rem;
}

@media only screen and (max-width: 520px) {
    #titulo{
        font-size: 1.4rem;
    }
    #footer{
        margin-top: 20px;
    }
    #footer *{
        font-size: 0.9rem;
    }
    #tituloFooter{
        font-size: 1.2rem;
    }
    #textoAbout{
        margin: -30px -20px 0 -20px;
    }
    #aptitudes{
        margin-top: -10px;
    }
    #aptitudes *{
        font-size: 1rem;
    }
    #tituloBanner{
        font-size: 1rem;
    }
}